import ArrowRight from 'images/homepage/ArrowRight';
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

interface EmailDialogProps {
  paymentLink: string;
  children: React.ReactElement;
  onClose?: () => void;
}

const EmailDialog: React.FC<EmailDialogProps> = ({ paymentLink, children, onClose }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(true);
  const emailInputRef = useRef<HTMLInputElement>(null);

  const validateEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
    setIsValid(true);
  };

  const handleClose = (): void => {
    setIsOpen(false);
    if (onClose) onClose();
  };

  const handleContinue = (): void => {
    if (!validateEmail(email)) {
      setIsValid(false);
      return;
    }
    const redirectUrl = `${paymentLink}?prefilled_email=${encodeURIComponent(email)}`;
    window.location.href = redirectUrl;
  };

  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent): void => {
      if (e.key === 'Escape') handleClose();
    };
    
    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      emailInputRef.current?.focus();
    }
    
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen]);

  const customStyles = `
    .email-input:focus {
      border-color: rgba(29, 185, 84, 0.4) !important;
      box-shadow: 0 0 0 0.12rem rgba(29, 185, 84, 0.25) !important;
    }
    .claim-btn {
        font-family: "Gilroy";
        font-weight: 600;
        border: none;
        color: #fff;
        border-radius: 100px;
        background: #000000;
        padding: 10px 26px;
        margin-top: 24px;
        width: 160px;
        display: inline-flex;
        justify-content: space-between;
        transition:
            width 0.1s linear,
            background-color 0.1s linear;
        text-decoration: none;

        &:focus {
            outline: 4px solid rgba(0, 0, 0, 0.2);
        }

        &:hover {
            background-color: #1db954;
            width: 160px;
        }

        @media (min-width: 768px) {
            width: 150px;
        }
    }
    .modal-dialog {
      max-width: 380px !important;
    }
    .modal-header {
      align-items: center !important;
      padding: 24px 24px 12px 24px !important;
    }
    .modal-body {
      padding: 0px 24px !important;
    }
    .modal-footer {
      padding-top: 0 !important;
      padding-bottom: 20px !important;
    }
    .modal-title {
      margin: 0 !important;
      line-height: 1 !important;
      font-size: 18px !important;
      color: black !important;
    }
    .btn-close {
      padding: 0 !important;
      margin: 0 !important;
      width: 16px !important;
      height: 16px !important;
      opacity: 0.65 !important;
      background-size: 16px !important;
    }
    .btn-close:hover {
      opacity: 1 !important;
    }
  `;

  const trigger = React.cloneElement(children, {
    onClick: () => setIsOpen(true)
  });

  return (
    <>
      {trigger}
      {isOpen && ReactDOM.createPortal(
        <>
          <style>{customStyles}</style>
          <div 
            className="modal fade show" 
            style={{ 
              display: 'block',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              zIndex: 1050 
            }} 
            onClick={handleBackdropClick}
            tabIndex={-1} 
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered" onClick={e => e.stopPropagation()}>
              <div className="modal-content">
                <div className="modal-header border-0">
                  <h5 className="modal-title">Registered email</h5>
                  <button 
                    type="button" 
                    className="btn-close" 
                    onClick={handleClose}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <p className="text-muted mb-3">
                    Please enter your registered email address on Ente to continue with the purchase.
                  </p>
                  <input
                    ref={emailInputRef}
                    type="email"
                    className={`form-control email-input ${!isValid ? 'is-invalid' : ''}`}
                    placeholder="your@email.com"
                    value={email}
                    onChange={handleEmailChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleContinue();
                      }
                    }}
                    aria-label="Email address"
                  />
                  {!isValid && (
                    <div className="invalid-feedback">
                      Please enter a valid email address
                    </div>
                  )}
                </div>
                <div className="modal-footer border-0">
                  <button 
                    type="button" 
                    className="btn btn-success claim-btn"
                    onClick={handleContinue}
                  >
                    Claim  <ArrowRight />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>,
        document.body
      )}
    </>
  );
};

export default EmailDialog;
