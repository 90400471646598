import React from "react";
import { ISVGProps } from "types";

export default function Plus(props: ISVGProps) {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6 18C6 17.1716 6.67157 16.5 7.5 16.5H28.5C29.3284 16.5 30 17.1716 30 18C30 18.8284 29.3284 19.5 28.5 19.5H7.5C6.67157 19.5 6 18.8284 6 18Z"
                fill="currentcolor"
            />
            <path
                d="M18 6C18.8284 6 19.5 6.67157 19.5 7.5L19.5 28.5C19.5 29.3284 18.8284 30 18 30C17.1716 30 16.5 29.3284 16.5 28.5L16.5 7.5C16.5 6.67157 17.1716 6 18 6Z"
                fill="currentcolor"
            />
        </svg>
    );
}
